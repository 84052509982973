.App {
  font-family: scheme, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-top: 10rem;
}

.App .hero {
  text-align: center;
  font-size: 5rem;
  margin-bottom: 0.2rem;
}

.App .subtitle {
  text-align: center;
}
